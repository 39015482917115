/**
 * CTA Page component.
 */

.pc-cta-style,
.pc-cta-style-button {
  .field-name-pc-cta-action-button {
    width: 100%;
  }

  .pc-cta-action--button {
    display: block;
    padding: 1rem;
    text-decoration: none;
    width: 100%;

    &:hover {
      transition: all 250ms ease-in;
    }

    &-link {
      display: block;
      font-weight: bold;
    }

    &-info {
      display: block;
    }
  }
}

.pc-cta-style {
  background-color: transparent;
  border: 1px solid transparent;
  padding: .5rem;

  .field-name-pc-cta-action-button {
    display: table;
    min-height: 4em;
    text-align: center;
  }

  .pc-cta-action--button {
    display: table-cell;
    padding: 0 1rem;
    vertical-align: middle;
  }
}

.pc-cta-style-button {
  .pc-cta-action--button {
    border-radius: $border-radius-base;
    padding: 1rem;
  }
}


// Colors ---------------------------------------------------------------------
.pc-cta-style-bordeaux,
.pc-cta-style-button-bordeaux {
  border-color: $color_red;

  .pc-cta-action--button {
    background-color: $color_red;
    color: $color_white;

    &:hover {
      background-color: $color_grey;
      color: $color_black;
    }
  }
}

.pc-cta-style-dark-grey,
.pc-cta-style-button-dark-grey {
  border-color: $color_grey_dark;

  .pc-cta-action--button {
    background-color: $color_grey_dark;
    color: $color_white;

    &:hover {
      background-color: $color_grey;
      color: $color_black;
    }
  }
}

.pc-cta-style-grey,
.pc-cta-style-button-grey {
  border-color: $color_grey;

  .pc-cta-action--button {
    background-color: $color_grey;
    color: $color_black;

    &:hover {
      background-color: $color_grey_dark;
      color: $color_white;
    }
  }
}

.pc-cta-style-brown,
.pc-cta-style-button-brown {
  border-color: $color_brown_light;

  .pc-cta-action--button {
    background-color: $color_brown_light;
    color: $color_white;

    &:hover {
      background-color: $color_grey;
      color: $color_black;
    }
  }
}

.pc-cta-style-white,
.pc-cta-style-button-white {
  border-color: $color_white;

  .pc-cta-action--button {
    background-color: $color_white;
    color: $color_black;

    &:hover {
      background-color: $color_grey_dark;
      color: $color_white;
    }
  }
}
