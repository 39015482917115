/**
 * Social links styling.
 */

.rrssb {
  margin: 3rem 0;

  ul.rrssb-buttons {
    padding: 0;
    width: 200px;

    li {
      padding-right: 10px;

      a {
        height: 30px;
        width: 30px;
        @include border_rounded(15px);
      }

      .rrssb-icon {
        top: 0;
        left: 0;
        height: 30px;
        width: 30px;

        background-position: center;
        background-repeat: no-repeat;
        background-size: 50%;

        svg {
          display: none;
        }
      }

      .rrssb-text {
        display: none;
      }
    }
  }

  @media #{$break_desktop} {
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
  }
}
