// ========================================================================== //
// Pagination styles.
//
// Override default Bootstrap's styles.
// ========================================================================== //

.pager {
  li.pager-next a,
  li.pager-previous a {
    background-color: transparent;
    text-decoration: none;
    @include border_reset();

    &:hover {
      text-decoration: underline;
    }
  }
}
