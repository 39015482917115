//
// Navbar (main navigation).
//

@media (max-width: ($width_desktop - 1)) {
  .navbar-header {
    float: none;
  }
  .navbar-left,.navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none!important;
  }
  .navbar-nav {
    float: none!important;
    margin-top: 7.5px;
  }
  .navbar-nav>li {
    float: none;
  }
  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collapse.in{
    display:block !important;
  }
}


// Navbar container.
#navbar {
  .navbar-header {
    margin-right: 0;
    margin-left: 0;
  }

  // Default link style.
  ul {
    a {
      color: $color_white;
      line-height: 20px;
      text-decoration: none;
      text-align: center;

      &.active, &.active-trail {
        background-color: $color_brown;
      }

      &:hover {
        background-color: $color_white;
        color: $color_brown;
      }
    }
  }

  // Mobile open/close menu button.
  .navbar-toggle {
    background-color: $color_brown_light;
    border: none;
    float: left;
    margin: 10px 0 0 15px;

    .icon-bar {
      background-color: $color_brown_dark;
    }

    &:hover {
      .icon-bar {
        background-color: $color_white;
      }
    }

    @media #{$break_mobile} {
      display: inherit;
    }

    @media #{$break_desktop} {
      display: none;
    }
  }

  // Mobile dropdown menu.
  .navbar-collapse {
    background-color: $color_brown_light;
    margin: 0;
    padding: 0;
    margin-top: 10px;

    @media #{$break_tablet} {
      margin-top: 50px;
    }
  }

  nav {
    padding: 50px 15px 6000px 15px;

    ul.secondary,
    ul.primary-sub,
    ul.language-switcher-locale-url {
      margin-top: 20px;
      float: none;
    }

    ul.language-switcher-locale-url {
      border-top: 1px solid #62605f;
      text-align: center;
      margin: 2rem 0;
      padding: 1rem 0;

      li {
        display: inline-block;
        padding: 0 1rem;
      }

      a {
        display: block;
        text-transform: uppercase;
        padding: 0 2rem;
      }
    }
  }

  // Desktop (menu normal).
  @media #{$break_desktop} {
    nav {
      padding: 0;
    }

    .navbar-collapse {
      background-color: transparent;
      margin: 0;
      padding: 0;
    }

    .nav-primary {
      background-color: $color_red;
      margin: 0;
      position: absolute;
      top: 150px;
      right: 0;
      left: 0;
      width: 100%;

      .nav-primary--container {
        @include clearfix;
        max-width: $width_tablet;
        margin: 0 auto;

        ul.primary {
          li {
            padding: 0;

            a {
              border-left: 1px solid $color_white;
              font-size: $font-size-base;
              line-height: 30px;
              padding: 0 1em;

              &.active {
                background-color: $color_white;
                color: $color_red;
              }
            }

            &:first-child {
              a {
                border-left: 0;
              }
            }
          }
        }
      }
    }

    .nav-secondary {
      background-color: $color_grey;
      margin: 0;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: 100%;

      .nav-secondary--container {
        @include clearfix;
        max-width: $width_tablet;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        ul.secondary,
        ul.primary-sub,
        ul.language-switcher-locale-url {
          list-style: none;
          margin: 0;

          li {
            float: left;
          }

          a {
            color: $color_black;
            display: block;
            font-size: $font-size-base;
            line-height: 25px;
            padding: 0 1em;

            &:hover,
            &.active {
              background-color: $color_brown;
              color: $color_white;
            }
          }
        }

        ul.language-switcher-locale-url {
          border-top: none;
          margin: 0 0 0 2rem;
          padding: 0;
          text-align: left;

          li {
            display: inline-block;
            padding: 0;
          }

          a,
          .locale-untranslated {
            font-size: 14px;
            padding: 0 1rem;
          }

          .locale-untranslated {
            color: #999;
            text-decoration: none;
            text-transform: uppercase;
            cursor: not-allowed;
          }
        }
      }
    }
  }


  @media #{$break_desktop} {
    .nav-primary .nav-primary--container,
    .nav-secondary .nav-secondary--container {
      max-width: $width_desktop;
    }

    .nav-primary {
      top: 215px
    }
  }

  @media #{$break_large} {
    .nav-primary .nav-primary--container,
    .nav-secondary .nav-secondary--container {
      max-width: $width_large;
    }
  }

}
