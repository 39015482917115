//
// Content - Main navivation.
//

#pc-nav-main-1 {
  margin: 3em 0 0;
  padding-bottom: 0;

  @media #{$break_desktop} {
    padding-bottom: 1em;
  }
}
#pc-nav-main-2 {
  margin: 0 0 3em;
  padding-top: 0;

  @media #{$break_desktop} {
    padding-top: 1em;
  }
}

#pc-nav-main-1,
#pc-nav-main-2 {
  .pc-columns-layout-3 > .field-items > .field-item {
    @media #{$break_desktop} {
      margin: 0;
      padding-top: 0;
      padding-bottom: 0;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  .pc-cta-style-button-bordeaux .pc-cta-action--button {
    background-color: transparent;
    border: 1px solid $color_red;
    color: $color_black;
    height: 85px;
    text-align: center;
    vertical-align: middle;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $color_red;
      color: $color_white;
    }
  }
}
