/**
 * Media related.
 */

.media {
  margin: 15px 0;

  img.file-icon {
    padding-right: 5px;
  }
}
