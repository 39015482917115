//
// Styling of the highlights paragraph.
//

.entity-paragraphs-item.paragraphs-item-pc-highlight {
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  .content {
    order: 2;
  }

  .content--image {
    order: 1;
    overflow: hidden;
  }

  .field-name-pc-field-link {
    margin-top: 1rem;
  }

  a.teaser-overlay-link {
    border-bottom: 0;
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-indent: 200%;
    white-space: nowrap;
    z-index: 1;
    overflow: hidden;
  }
}

@media #{$break_tablet} {
  .entity-paragraphs-item.paragraphs-item-pc-highlight {
    margin-bottom: 0;
  }

  .highlights--1 {
    .paragraphs-item-pc-highlight {
      flex-direction: row;

      .content,
      .content--image {
        flex: 1;
        padding: 2rem;
      }

      h3.component-title {
        margin-top: 0;
      }
    }
  }

  .highlights--2,
  .highlights--3 {
    .field-name-pc-highlight > .field-items {
      display: flex;
      > .field-item {
        flex: 1;
        padding: 2rem;
      }
    }
  }

  .highlights--1,
  .highlights--2 {
    .content {
      max-width: 768px;
      margin: 0 auto;
    }
  }

  .highlights {
    .field-name-pc-highlight {
      margin: 0 -2rem;
    }
  }
}
