//
// Paragraphs - Links.
//

.paragraphs-item-links {
  $icon-size: 3rem;
  $circle-size: 1.5em;
  $circle-height: 2 * $circle-size;

  .field-name-pc-field-text {
    margin-bottom: 1em;
  }

  .field-name-pc-field-links {
    .field-item {
      display: table;
      margin-bottom: 1em;
      width: 100%;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      border: 1px solid $color_red;
      border-radius: $border-radius-base;
      color: $color_black;
      display: table-cell;
      font-family: $font_stack !important;
      font-weight: bold;
      padding: 2rem 1rem;
      text-decoration: none;
      vertical-align: middle;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      &::before {
        @extend %icon;
        background-color: $color-red;
        border-radius: $circle-size;
        color: $color_white;
        display: block;
        font-size: $icon-size;
        line-height: $circle-size;
        text-align: center;
        vertical-align: middle;
        width: $circle-size;
      }

      &:hover {
        background-color: $color_red;
        color: $color_white;
        transition: all 250ms ease-in;

        &::before {
          background-color: $color-white;
          color: $color_red;
          transition: all 250ms ease-in;
        }
      }

      &.link-no-icon {
        &::before {
          display: none;
        }
      }

      &.link-text-center {
        text-align: center;
      }
    }
  }

  &.pc-links-style-box {
    $box-height: $icon-size + 4rem;

    .field-item {
      a {
        height: $box-height;
        padding-left: $box-height;
        position: relative;
        vertical-align: middle;

        &::before {
          position: absolute;
          left: 1rem;
          top: 1rem;
        }

        &.link-no-icon {
          padding-right: 2rem;
          padding-left: 2rem;

          &::before {
            display: none;
          }
        }
      }
    }
  }

  &.pc-links-style-button {
    .field-item {
      @include font_size_h3;

      a {
        background-color: $color_red;
        color: $color_white;
        font-weight: normal;
        padding: 1rem;
        text-align: center;

        &::before {
          background-color: $color_white;
          color: $color_red;
          margin: 0 auto 1rem;
        }

        &:hover {
          background-color: $color_white;
          color: $color_red;
          transition: all 250ms ease-in;

          &::before {
            background-color: $color_red;
            color: $color_white;
            transition: all 250ms ease-in;
          }
        }

        &.logo-vdab-loopbaancheques,
        &.logo-kmo-portefeuille,
        &.logo-b2bspeak,
        &.logo-certo {
          background-color: transparent;
          background-position: center;
          background-size: auto 100%;
          border: 0;
          height: 85px;
          text-indent: -9999px;

          @media #{$break_desktop} {
            height: 95px;
          }
        }

        &.logo-vdab-loopbaancheques {
          background-image: url('../images/logo/vdab-loopbaancheques@2x.png');
        }

        &.logo-kmo-portefeuille {
          background-image: url('../images/logo/kmo-portefeuille@2x.png');
        }

        &.logo-b2bspeak {
          background-image: url('../images/logo/b2bspeak@2x.png');
        }

        &.logo-certo {
          background-image: url('../images/logo/certo@2x.png');
        }
      }

      a.link--white-grey {
        background-color: transparent;
        border: 1px solid $color_grey_dark;
        color: $color_grey_dark;

        &::before {
          background-color: $color_grey_dark;
          color: $color-white;
        }

        &:hover {
          background-color: $color_grey_dark;
          color: $color_white;
          transition: all 250ms ease-in;

          &::before {
            background-color: $color_white;
            color: $color_grey_dark;
            transition: all 250ms ease-in;
          }
        }
      }
    }
  }
}
