//
// Cookie consent banner.
//

#sliding-popup {
  border-top: 5px solid #fff;
  .eu-cookie-compliance-banner {
    .popup-content {
      padding: 1rem 0;
    }

    #popup-text {
      button {
        margin: 0;
        padding: 0;
        line-height: inherit;
        font-size: 12px;
        font-weight: bold;
        text-decoration: underline;
      }
    }

    #popup-buttons {
      button {
        border: 1px solid #fff;
        box-shadow: none;
        background-image: none;
        border-radius: 5px;
        background-color: #9b1b3f;
        color: #fff;
        font-weight: bold;
        padding: 4px 8px;
        text-shadow: none;

        &:hover {
          background-color: #fff;
          color: #9b1b3f;
        }

        &.eu-cookie-compliance-secondary-button {
          background-color: #fff;
          color: #9b1b3f !important;

          &:hover {
            background-color: #9b1b3f;
            color: #fff !important;
          }
        }
      }
    }
  }
}




