/**
 * Page footer styles.
 */

footer.footer {
  background-color: $color_black;
  padding: 0;

  * {
    color: $color_white;
  }
  a {
    text-decoration: none;

    &:hover {
      color: $color_white;
      text-decoration: underline;
    }
  }
}
