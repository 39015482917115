/**
 * General Page components styling.
 */

// Page title.
h1.page-header {
  @include font_size_h2;
  color: $color_brown;
  border-bottom: 1px solid $color_grey_medium;
  margin: 4rem 0 2rem;
  padding: 0 0 1rem 0;
}

// TEMPORARY DISABLE INSERT POINT!
.insert-point {
  display: none;
}

// General styling.
.entity-paragraphs-item {
  @include margin(30px 0);

  .entity-paragraphs-item {
    margin: 0;
  }
}



// Logo.
.pc-logo > .content {
  padding-top: 150px;

  @include image_background_responsive("../images/logo", "png", 170px, 123px);
  background-repeat: no-repeat;
  background-position: center 10px;
}


// Background styles.
.pc-style-background {
  padding: 1rem 2rem;

  &.pc-style-none {
    @include padding(10px 0);
  }

  &.pc-style-background-grey {
    background-color: $color_grey;
    @include margin(0);
  }
  &.pc-style-background-brown {
    background-color: $color_brown_light;
    @include margin(0);

    * {
      color: $color_white;
    }
  }
  &.pc-style-background-black {
    background-color: $color_black;
    @include margin(0);

    * {
      color: $color_white;
    }
  }

  @media #{$break_mobile} {
    &.pc-style-background-grey,
    &.pc-style-background-brown,
    &.pc-style-background-black {
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}


// Title styles.
.field-name-pc-field-title {
  color: $color_brown;

  h1 {
    @include font_size_h2;
    border-bottom: 1px solid $color_grey_medium;
    margin: 3rem 0 2rem;
    padding: 0 0 1rem 0;
  }
}
.pc-columns-layout {
  h1 {
    text-align: left;
    border-bottom: none;
    border-top: none;
  }
}

// Max width of components.
.paragraphs-item-pc-text > .content,
.paragraphs-item-pc-image > .content {
  max-width: $width_tablet;
  margin-left: auto;
  margin-right: auto;
}

footer .paragraphs-item-pc-text > .content {
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
}

// Footnote.
.field-name-pc-field-footnote {
  border-bottom: 1px solid $color_brown;
  border-top: 1px solid $color_brown;
  clear: both;
  color: $color_brown;
  font-family: $font_stack_bold;
  text-align: center;
  @include padding(5px 0);
  @include margin(20px 0 0 0);
}


// Columns.
.field-name-pc-field-columns {
  padding: 0;

  & > .field-items {
    & > .field-item {
      margin: 1em 0 0;
      padding: 0;
    }
  }
}

@media #{$break_desktop} {
  .pc-columns-layout-2,
  .pc-columns-layout-2-12,
  .pc-columns-layout-2-21,
  .pc-columns-layout-3 {
    width: 100%;

    & > .field-items {
      display: flex;


      & > .field-item {
        padding: 0 1em;
        vertical-align: top;
      }
    }
  }

  .pc-columns-layout-2,
  .pc-columns-layout-2-12,
  .pc-columns-layout-2-21,
  .pc-columns-layout-3 {
    & > .field-items {
      &  > .field-item:first-child {
        padding-left: 0;
        padding-right: 2em;
      }
    }
  }

  .pc-columns-layout-2,
  .pc-columns-layout-2-12,
  .pc-columns-layout-2-21,
  .pc-columns-layout-3 {
    & > .field-items {
      & > .field-item:last-child {
        padding-right: 0;
        padding-left: 2em;
      }
    }
  }

  .pc-columns-layout-2 > .field-items > .field-item {
    width: 50%;
  }
  .pc-columns-layout-2-12 > .field-items > .field-item:first-child,
  .pc-columns-layout-3 > .field-items > .field-item {
    width: 33%;
  }
  .pc-columns-layout-2-21 > .field-items > .field-item:first-child {
    width: 67%;
  }
  .pc-columns-layout-2-12 > .field-items > .field-item:last-child {
    width: 67%;
  }
  .pc-columns-layout-2-21 > .field-items > .field-item:last-child {
    width: 33%;
  }
}


// Slider.
.paragraphs-item-pc-slider {
  @include rem(padding-top, 40px);
}
.paragraphs-item-pc-slider-item {
  padding-bottom: 2em;

  .field-name-pc-field-title {
    h1 {
      border-bottom: none;
      border-top: none;
      @include padding(0);
      @include margin(0);
    }
  }
  .field-name-pc-field-subtitle h3 {
    text-align: center;
    @include font_size_h3;
    @include margin(10px 0 0 0);
  }
  .paragraphs-items-pc-field-columns {
    border-top: 1px solid $color_brown;
    @include margin(20px 0 0 0);
  }
  .paragraphs-item-pc-image {
    padding-top: 20px;
  }

  @media #{$break_tablet} {
    padding-bottom: 0;
  }

  @media #{$break_desktop} {
    .paragraphs-item-pc-image {
      padding-top: 60px;
    }
  }
}
.slick-prev,
.slick-next {
  top: 25px;
  z-index: 9999;

  &:before {
    color: $color_white;
    @include font_size_responsive(30px, 40px);
  }

  @media #{$break_tablet} {
    top: 30px;
  }
}
.slick-prev {
  left: 0;
}
.slick-next {
  right: 10px;

  @media #{$break_tablet} {
    right: 15px;
  }
  @media #{$break_desktop} {
    right: 17px;
  }
}



// Quote.
.paragraphs-item-pc-quote {
  * {
    color: $color_brown_light;
    font-style: italic;
  }

  .field-name-pc-field-quote-text .field-item {
    text-align: left;
    line-height: $font_line_height_heading;
    @include font_size_h2;
  }

  .field-name-pc-field-quote-author {
    @include margin(10px 0 0 0);
    text-align: right;
    @include rem('font-size', 18px);
  }
}

// Site component.
.paragraphs-item-pc-site-component.pc-style-background {
  margin: 0;
  padding: 0;
}

// Content list.
.paragraphs-item-pc-content-list {
  & > .content {
    max-width: $width_tablet;
    margin-left: auto;
    margin-right: auto;
  }

  article {
    margin-bottom: 30px;

    h2 a {
      text-decoration: none;
      color: $color_black;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

// Event teasers.
.paragraphs-item-pc-events-teasers,
.paragraphs-item-pc-events-overview {
  .field-name-pc-field-list-settings {
    max-width: $width_tablet;
    margin-left: auto;
    margin-right: auto;

    article {
      margin-bottom: 30px;

      h2 a {
        text-decoration: none;
        color: $color_black;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}


// Image.
.paragraphs-item-pc-image {
  .img-responsive {
    margin: 0 auto;
  }
}


// Form.
.paragraphs-item-pc-signup-form,
.paragraphs-item-pc-form {
  padding-top: 2em;
  padding-bottom: 2em;

  .field-name-pc-field-title {
    h2 {
      @include font-size_h1;
      text-align: center;
      padding-left: 1em;
      padding-right: 1em;
      padding-bottom: 1em;
    }
  }

  .btn {
    float: right;
    padding-left: 2em;
    padding-right: 2em;
    text-transform: uppercase;
  }

  @media #{$break_desktop} {
    &.pc-logo > .content {
      background-position: 70px bottom;
      padding-top: 0;

      .field-name-pc-field-title {
        padding-bottom: 150px;
      }
    }

    .field-name-pc-field-title,
    .field-name-pc-field-form,
    .pc-signup-form--form {
      float: left;
    }

    .field-name-pc-field-title {
      float: left;
      padding-right: 1em;
      width: 33%;
    }
    .field-name-pc-field-form,
    .pc-signup-form--form {
      float: left;
      padding-right: 1em;
      width: 67%;
    }

    &.pc-logo > .content {
      background-position: 70px bottom;
    }
  }

  @media #{$break_large} {
    &.pc-logo > .content {
      background-position: 95px bottom;
    }
  }
}

.paragraphs-item-pc-signup-form {
  .form-item-course.form-type-radios {
    margin: 5rem 0;

    & > label {
      font-size: 1.25em;
    }

    .form-type-radio {
      margin: 2rem 0;
    }
  }

  .pc-signup-form--confirmation {
    font-size: 1.25em;
    font-weight: bold;

    &::before {
      content: '\2714';
      color: #386f1e;
      display: inline-block;
      font-size: 1.5em;
      padding-right: 1rem;
    }

    @media #{$break_desktop} {
      position: absolute;
      bottom: 5rem;
    }
  }
}


/* Analysts overview */
.paragraphs-item-pc-analysts {
  padding-top: 2em;
  padding-bottom: 2em;

  .field-name-pc-field-title {
    h2 {
      @include font-size_h1;
      text-align: center;
      padding-left: 1em;
      padding-right: 1em;
      padding-bottom: 1em;
    }
  }

  .views-exposed-form {
    .views-exposed-widget {
      float: none;
    }
  }

  article.node {
    padding: 1em;

    a:hover {
      color: $color_red;
    }

    .ct-analyst-image {
      float: left;
      width: 70px;
    }
    .ct-analyst-content {
      margin-left: 70px;
    }

    header h3 {
      @include font-size-h3;
      padding-top: 0;
      margin-top: 0;

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &.pc-style-background-brown article.node {
    background-color: $color_white;

    * {
      color: $color_brown;
    }
  }

  &.pc-style-background-brown .views-row article.node {
    margin: 1em 0;
  }

  @media #{$break_tablet} {
    .views-row {
      float: left;
      width: 50%;
    }
    .views-row-odd {
      padding-right: 1em;
    }
    .views-row-even {
      padding-left: 1em;
    }

    article.node {
      height: 120px;
    }

    .balloon article.node {
      height: auto;
    }
  }

  @media #{$break_desktop} {
    .field-name-pc-field-title {
      float: left;
      padding-right: 1em;
      height: 480px;
      width: 33%;
    }

    &.pc-logo > .content {
      background-position: 70px 320px;
      @include padding(10px);
    }

    .view-filters {
      float: left;
      padding-right: 1em;
      width: 33%;
    }
    .view-content-wrapper {
      float: left;
      left: 1em;
      width: 67%;
    }

    article.node {
      height: 140px;
    }

    .balloon article.node {
      height: auto;
    }
  }

  @media #{$break_large} {
    &.pc-logo > .content {
      background-position: 95px 320px;
    }
  }
}


// Form in brown box.
.pc-style-background.pc-style-background-brown {
  input.form-text,
  textarea.form-textarea,
  select.form-select option,
  .chosen-container * {
    color: $color_black;
  }
  .btn {
    background-color: $color_white;
    color: $color_brown;

    &:hover {
      background-color: $color_black;
      color: $color_white;
    }
  }
  .alert * {
    color: $color_black;
  }
}

// Site Component.
.paragraphs-item-pc-site-component {
  margin: 0;
}

// Video component.
// Responsive video.
.media-youtube-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  overflow: hidden;
}

.media-youtube-video iframe,
.media-youtube-video object,
.media-youtube-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
// In a page component.
.paragraphs-item-pc-video {

  @media #{$break_tablet} {
    .media-youtube-video {
      padding: 0;
      text-align: center;
    }

    .media-youtube-video {
      iframe,
      object,
      embed {
        position: relative;
        top: auto;
        left: auto;
        width: 640px;
        height: 390px;
      }
    }
  }
}
