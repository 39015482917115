//
// Homepage.
//

#pc-homepage-main {
  margin-top: 0;

  .field-name-pc-field-columns {
    & > .field-items  {
      & > .field-item {
        padding: 1em;

        &.odd {
          @include border_rounded($border-radius-large);
          background-color: $color_grey;
          padding: 0 1em;

          .component-title {
            margin: .2em 0 1em;
          }

          .paragraphs-item-pc-text {
            padding-top: 1em;
          }
        }
      }
    }
  }
}
