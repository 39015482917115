/**
 * Analyst layout.
 */

body.node-type-analyst {
  h1.page-header {
    max-width: 100%;
  }
}

#block-system-main > article.node-analyst {
  margin-top: 1em;

  .ct-analyst-sidebar {
    margin-bottom: 2em;

    .field-name-ct-analyst-photo {
      margin-bottom: 2em;

      img {
        display: block;
        margin: 0 auto;
      }
    }

    .field-name-ct-analyst-services {
      margin-bottom: 2em;
    }

    .field-name-ct-analyst-city-country {
      margin-bottom: 2em;
    }

    * {
      text-align: center;
    }
  }


  .field-name-ct-analyst-description {
    margin-bottom: 2em;
  }

  @media #{$break_desktop} {
    .ct-analyst-sidebar {
      float: left;
      padding-right: 1em;
      width: 33%;
    }
    .ct-analyst-content {
      float: left;
      padding-left: 1em;
      width: 67%;
    }
  }
}

// Map.
.paragraphs-item-pc-analysts .balloon article.node-analyst {
  margin: 0;
  padding: 0;

  h3 {
    @include rem(font-size, 16px);
  }
}

// Image.
img.image-style-analyst-default {
  border-radius: 65px;
  -moz-border-radius: 65px;
  -webkit-border-radius: 65px;
}
img.image-style-analyst-teaser {
  border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
}
