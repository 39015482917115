/**
 * Styles related to the analysts overview page.
 */

.view-v-analysts {
  margin-top: 2rem;

  // View exposed filters.
  .view-filters {
    background-color: $color_grey;
    box-sizing: content-box;

    .views-exposed-form {
      padding: 10px;

      .views-exposed-widget {
        float: none;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      }

      .form-type-geofield-proximity {
        margin: 0;

        .form-item {
          margin: 0;
          padding: 0;
        }

        .form-item-field-geofield-distance-origin {
          width: 80%;
          float: left;
        }
        .form-item-field-geofield-distance-distance {
          width: 20%;

          .chosen-container {
            border-left: 1px solid $color_brown_light;
          }
        }
      }

      .input-group-addon,
      .input-group-addon:last-child {
        border: none;
        border-radius: 0;
      }

      button {
        @include font_cta;
        @include padding(10px);
      }

      .views-submit-button {
        float: left;
        width: auto;

        button {
          background-color: $color_white;
          color: $color_black;

          &:hover {
            background-color: $color_black;
            color: $color_white;
          }
        }
      }
      .views-reset-button {
        float: left;
        padding-left: 20px;
        width: auto;

        button {
          background-color: $color_grey;

          &:hover {
            background-color: $color_black;
            color: $color_white;
          }
        }
      }
    }

    @media #{$break_desktop} {
      float: left;
      height: 430px;
      width: 460px;

      .views-exposed-form {
        padding-right: 20px;
      }
    }

    @media #{$break_large} {
      width: 465px;
    }
  }

  // Views map content (attachment).
  .view-content-attachment {
    article {
      .field-name-ct-analyst-photo {
        img {
          margin: 0 auto;
        }
      }
      .ct-analyst-content {
        text-align: center;

        h3 {
          margin-top: 10px;

          a {
            color: $color_link;
            text-decoration: none;

            &:hover {
              color: $color_link_hover;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  // No results.
  .view-empty {
    float: left;
    padding-left: 10px;
    padding-top: 20px;
    @include font_size_h3;
  }


  // Views main content.
  .view-content-main {
    margin: 20px -10px 0 -10px;

    .views-row {
      padding: 10px;

      article {
        background-color: $color_grey;
        padding: 10px;

        .field-name-ct-analyst-photo {
          float: left;
        }

        .ct-analyst-content {
          margin-left: 70px;
        }

        h3 {
          margin-top: 0;

          a {
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    @media #{$break_tablet} {
      .views-row {
        width: 50%;
        float: left;
      }
    }
    @media #{$break_desktop} {
      .views-row {
        width: 25%;

        article {
          height: 200px;
          padding-top: 20px;

          .field-name-ct-analyst-photo {
            float: none;

            img {
              margin: 0 auto;
            }
          }

          .ct-analyst-content {
            margin-left: 0;
            text-align: center;

            h3 {
              margin-top: 10px;
            }
          }
        }
      }
    }
    @media #{$break_large} {
      .views-row {
        width: 20%;
      }
    }
  }

}
