//
// Overview of events.
//

.view-v-event-list {
  h3.event-description {
    @include font_size_default;

    a {
      color: $color_grey_dark;
      text-decoration: none;

      &:hover {
        color: $color_red;
      }
    }
  }
}
