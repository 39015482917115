
// -------------------------------------------------------------------------- //
// Padding in rem with px fallback.
//
// Usage:
// <code>
//   .btn {
//     @include padding(10px 0px);
//   }
// </code>
//
// Result:
// <pre>
//   .btn {
//     padding: 10px 0px;
//     padding: 1rem 0rem;
//   }
// </pre>
//
// @param string $values
//   The padding values in px or rem as when creating a default padding
//   definition. Default 0px.
// -------------------------------------------------------------------------- //
@mixin padding($values: 0px) {
  @include rem(padding, $values);
}

// -------------------------------------------------------------------------- //
// Margin in rem with px fallback.
//
// Usage:
// <code>
//   h1 {
//     @include margin(10px 0px 20px 5px);
//   }
// </code>
//
// Result:
// <pre>
//   h1 {
//     margin: 10px 0px 20px 5px;
//     margin: 1rem 0rem 2rem 0.5rem;
//   }
// </pre>
//
// @param string $values
//   The margin values in px or rem as when creating a default margin
//   definition. Default 0px.
// -------------------------------------------------------------------------- //
@mixin margin($values: 0px) {
  @include rem(margin, $values);
}

// -------------------------------------------------------------------------- //
// Reset border & border-radius.
//
// This will remove the border and the border radius.
// -------------------------------------------------------------------------- //
@mixin border_reset {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: none;
}

// -------------------------------------------------------------------------- //
// Make the 4 corners rounded by the given value.
//
// Usage:
// <code>
//   button {
//     @include border_rounded(10px);
//   }
// </code>
// -------------------------------------------------------------------------- //
@mixin border_rounded($value: 0px) {
  border-radius: $value;
  -moz-border-radius: $value;
  -webkit-border-radius: $value;
}
