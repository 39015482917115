/**
 * Leaflet styling.
 */

.pc-style-background.pc-style-background-brown {
  .leaflet-container * {
    color: $color_black;
  }
}


