
// -------------------------------------------------------------------------- //
// Create a font-size by providing the responsive default and desktop size.
//
// Tablets use the "almost" the same font size as desktops. "Almost" - because
// the rem value is calculated based on the desktop base value, but the rem
// value for tablets is 0.9 of the desktop rem value.
//
// @var $size
//   The default font size in px or rem.
// @var $size_desktop
//   The desktop font size in px or rem.
// -------------------------------------------------------------------------- //
@mixin font_size_responsive($size, $size_desktop) {
  @include rem(font-size, $size, $rem_mobile);
  @media #{$break_tablet} {
    @include rem(font-size, $size_desktop);
  }
}
