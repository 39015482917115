/**
 * Page header styles.
 */

// Header.
#navbar {
  height: 100px;
  position: relative;
  margin: 0;
  background: transparent;
  border: none;
  padding: 0;

  & > .container {
    position: relative;
    z-index: 100;
  }

  @media #{$break_tablet} {
    height: 175px;
    margin-bottom: 10px;

    &.navbar.container {
      max-width: 100%;
      width: 100%;
    }
    & > .container {
      max-width: 100%;
    }
  }

  @media #{$break_desktop} {
    height: 240px;

    &.navbar.container {
      max-width: 100%;
      width: 100%;
    }
    & > .container {
      max-width: 100%;
    }
  }
  @media #{$break_large} {
    & > .container {
      max-width: 100%;
    }
  }
}

// Logo.
a#logo {
  display: block;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;

  width: 120px;
  height: 87px;

  @include image_background_responsive("../images/logo-small", "png", 120px, 87px);
  background-repeat: no-repeat;
  background-position: center;

  z-index: 2000;

  &:hover {
    background-color: transparent;
  }

  img {
    display: none;
  }

  @media #{$break_mobile} {
    top: 5px;
  }
  @media #{$break_tablet} {
    top: 48px;
  }
  @media #{$break_desktop} {
    @include image_background_responsive("../images/logo", "png", 170px, 123px);
    width: 170px;
    height: 123px;
    top: 71px;
  }
}


// Default no display.
.header-image {
  background-repeat: no-repeat;
  background-position: center;
  display: none;
  position: absolute;
  top: 0;
  width: 100%;
}

// Header mobile.
@media #{$break_mobile} {
  .header-image-mobile {
    display: block;
    height: 100px;
  }
}
// Header tablet.
@media #{$break_tablet} {
  .header-image-tablet {
    display: block;
    height: 150px;
  }
}
// Header desktop and larger.
@media #{$break_desktop} {
  .header-image-desktop {
    display: block;
    height: 215px;
  }
  .header-image-tablet {
    display: none;
  }
}

// Header text.
.header-text {
  color: $color_grey_dark;
  margin: 0 auto;
  padding: 15px 0 0;
  text-align: center;

  font-family: $font_stack;
  font-size: $font-size-h3;
  line-height: $font_line_height_heading;

  @media #{$break_tablet} {
    font-size: $font-size-h2;
  }

  @media #{$break_desktop} {
    font-size: $font-size-h1;
  }
}
