// ========================================================================== //
// Typography.
// ========================================================================== //

html {
  font-size: #{$rem_mobile / 16px}em;
  @media #{$break_tablet} {
    font-size: #{$rem_tablet / 16px}em;
  }
  @media #{$break_desktop} {
    font-size: #{$rem_desktop / 16px}em;
  }
}

// Global font family and size.
body {
  font-family: $font_stack;
  line-height: $font_line_height;
  color: $text_color;

  @include font_size_default;
}


// Different font variants based on the style & weight.
b, strong {
  font-family: $font_stack_bold;
  font-weight: $font_weight_bold;

  i, em {
    font-family: $font_stack_bold_italic;
    font-style: italic;
  }
}
i, em {
  font-family: $font_stack_italic;
  font-style: italic;

  b, strong {
    font-family: $font_stack_bold_italic;
    font-weight: $font_weight_bold;
  }
}

// Headings.
h1, h2, h3, h4, h5, h6 {
  line-height: $font_line_height_heading;
  @include margin(20px 0px 10px 0px);
}
h1 {
  font-family: $font_stack;
  @include font_size_h1;
}
h1.small {
  @include font_size_h2;
}

h2, h3, h4, h5, h6 {
  font-family: $font_stack;
}

h2 {
  @include font_size_h2;
}

h3 {
  @include font_size_h3;
}

h4 {
  @include font_size_h4;
}

h5 {
  @include font_size_h5;
}

h6 {
  @include font_size_h6;
}


// Paragraphs.
p {
  @include margin(0px 0px 10px 0);
}


// Links.
a {
  color: $color_link;
  text-decoration: underline;

  &:hover {
    color: $color_link_hover;
  }
}
