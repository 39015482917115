

@font-face {
  font-family: 'ct-www';
  src:  url('#{$icomoon-font-path}/ct-www.eot?v3');
  src:  url('#{$icomoon-font-path}/ct-www.eot?v3#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/ct-www.ttf?v3') format('truetype'),
    url('#{$icomoon-font-path}/ct-www.woff?v3') format('woff'),
    url('#{$icomoon-font-path}/ct-www.svg?v3#ct-www') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'],
%icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ct-www' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-education,
%icon-education {
  &:before {
    content: $icon-education;
  }
}

.icon-analists,
%icon-analists {
  &:before {
    content: $icon-analists;
  }
}

.icon-contact-at,
%icon-contact-at {
  &:before {
    content: $icon-contact-at;
  }
}

.icon-contact-mail,
%icon-contact-mail {
  &:before {
    content: $icon-contact-mail;
  }
}

.icon-contact-phone,
%icon-contact-phone {
  &:before {
    content: $icon-contact-phone;
  }
}

.icon-organisation,
%icon-organisation {
  &:before {
    content: $icon-organisation;
  }
}

.icon-search-analist,
%icon-search-analist {
  &:before {
    content: $icon-search-analist;
  }
}

.icon-search-counselor,
%icon-search-counselor {
  &:before {
    content: $icon-search-counselor;
  }
}

.icon-search-education,
%icon-search-education {
  &:before {
    content: $icon-search-education;
  }
}

.icon-search-question,
%icon-search-question {
  &:before {
    content: $icon-search-question;
  }
}

.icon-search,
%icon-search {
  &:before {
    content: $icon-search;
  }
}

.icon-book,
%icon-book {
  &:before {
    content: $icon-book;
  }
}
