//
// Components : Checkmarks.
//

ul.checkmarks {
  li {
    position: relative;
    list-style: none;
    margin-bottom: .25em;

    &::before {
      position: absolute;
      display: block;
      content: '\2714';
      color: $color_green;
      left: -1.25em;
      font-size: 1.25em;
    }
  }
}