/**
 * Analyst layout.
 */

article.node-event {
  .ct-event-register-link {
    margin: 2rem 0;

    a {
      border: 1px solid $color_red;
      border-radius: $border-radius-base;
      background-color: $color_white;
      color: $color_red;
      display: inline-block;
      font-family: $font_stack;
      font-weight: bold;
      margin-bottom: 1rem;
      padding: .5rem 1rem;
      text-decoration: none;
      vertical-align: middle;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      &.ct-event-register-link--info {
        margin-right: 1rem;
      }

      &:hover {
        background-color: $color_red;
        color: $color_white;
        transition: all 250ms ease-in;
      }
    }
  }

  .event-description {
    font-weight: bold;
    margin-bottom: 1em;
  }

  .event-metadata {
    color: $color_brown ;
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;

    margin-bottom: 1em;

    * {
      display: inline;
    }

    span {
      text-transform: capitalize;
    }
  }
}

.field-name-ct-event-registration {
  background-color: $color_grey;
  margin-top: 1rem;
  margin-left: -15px;
  margin-right: -15px;
  padding: 3rem 15px;

  .field-label {
    color: $color_black;
    line-height: 1.2em;
    text-align: center;
    margin: 1rem 0rem 2rem 0rem;

    font-family: $font_stack;
    font-weight: normal;
    @include font_size_h1;
  }

  .field-items {
    label.control-label {
      color: $color_black;
    }

    .btn {
      background-color: $color_white;
      color: $color_black;
      float: right;
      font-family: $font_stack !important;
      text-transform: uppercase;

      &:hover {
        background-color: $color-grey_medium;
        color: #ffffff;
      }
    }
    #edit-cancel {
      display: none;
    }
  }

  .error-processed > label.control-label {
    color: $color_red;
  }

  @media #{$break_desktop} {
    margin-right: 0;
    margin-left: 0;
    padding: 2em 1rem;

    .field-label {
      margin: 2rem 0rem 1rem 0rem;
      float: left;
      width: 33%;
    }
    .field-items {
      float: left;
      width: 67%;
    }
  }
}
