/**
 * Global node styling.
 */

article > header {
  max-width: $width_tablet;
  margin: 1em auto;

  .submitted {
    color: $color_grey_medium;
    display: block;
    text-align: right;

    @include font_size_h6;
  }
}
