// ========================================================================== //
// Proportion/dimension related mixins.
// ========================================================================== //


// -------------------------------------------------------------------------- //
// Get an integer out of the given string.
//
// Source: @link http://hugogiraudel.com/2013/03/18/ultimate-rem-mixin/
//
// @param string $n
//   The string to parse.
//
// @return int
//   The parsed integer.
// -------------------------------------------------------------------------- //
@function parseInt($n) { /* 2 */
  @return $n / ($n * 0 + 1);
}


// -------------------------------------------------------------------------- //
// Create a property with values in px and rem.
//
// Source: @link http://hugogiraudel.com/2013/03/18/ultimate-rem-mixin/
//
// Usage:
// <code>
//   body {
//     @include rem(font-size, 1.6rem);
//     @include rem(padding, 20px 10px);
//     @include rem(padding, 20px 10px, 10px);
//   }
// </code>
//
// Will result in:
// <pre>
//   body {
//     font-size: 16px;  /* Fallback for IE8 */
//     font-size: 1.6rem;
//     padding: 20px 10px; /* Fallback for IE8 */
//     padding: 2rem 1rem;
//   }
// <pre>
//
// @param string $property
//   The name of the property to create.
// @param string $values
//   The values to use to create the property values.
//   Values can be in px or em. They will be automatically converted.
// @param string $base_size
//   The base value to do the px to rem transformation.
// -------------------------------------------------------------------------- //
@mixin rem($property, $values, $base_size: $rem_desktop, $important: "") {
  $px : ();
  $rem: ();

  // Base size is provided in px. Transform it into value.
  $base_size: parseInt($base_size);

  @each $value in $values {
    @if $value == 0 or $value == auto {
      @if $ie8_support {
        $px : append($px , $value);
      }
      $rem: append($rem, $value);
    }
    @else {
      $unit: unit($value);
      $val: parseInt($value);

      @if $unit == "px" {
        @if $ie8_support {
          $px : append($px,  $value);
        }
        $rem: append($rem, (($val / $base_size) + rem));
      }

      @if $unit == "rem" {
        @if $ie8_support {
          $px: append($px, (($val * $base_size) + px));
        }
        $rem: append($rem, $value);
      }
    }
  }

  @if $px == $rem {
    #{$property}: $px#{$important};
  }
  @else {
    @if $ie8_support {
      #{$property}: $px#{$important};
    }
    #{$property}: $rem#{$important};
  }
}
