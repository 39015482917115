
// -------------------------------------------------------------------------- //
// Create a resolution responsive background image.
//
//
// @var $file
//   The normal (low resolution) filename without the file extension..
// @var $extension
//   The file extension.
// @var $width
//   The width of the image on screen.
// @var $height
//   The height of the image on screen.
// -------------------------------------------------------------------------- //
@mixin image_background_responsive($file, $extension, $width, $height) {
  background-image: url(#{$file}.#{$extension});

  @media #{$break_retina} {
    background-image: url(#{$file}@2x.#{$extension});
    background-size: $width $height;
  }
}
