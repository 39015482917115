$icomoon-font-path: '../fonts/ct-www' !default;

$icon-education: '\e900';
$icon-academy: '\e900';
$icon-analists: '\e901';
$icon-contact-at: '\e902';
$icon-contact-mail: '\e903';
$icon-contact-phone: '\e904';
$icon-organisation: '\e905';
$icon-search-analist: '\e906';
$icon-search-counselor: '\e907';
$icon-search-education: '\e908';
$icon-search-question: '\e909';
$icon-search: '\e90a';
$icon-book: '\e90b';
