// ========================================================================== //
// Variables for the CoreTalents theme.
//
// Comments are here in // as that type is not rendered in the output.
// ========================================================================== //


// -------------------------------------------------------------------------- //
// Responsive breakpoints.
//
// Available variables:
// - $break_mobile : Mobile phones.
// - $break_tablet : Portrait tablets.
// - $break_desktop : Landscape tablets and desktops.
// - $break_large : Large screens.
//
// Usage:
// <code>
//   @media #{$break_mobile} {
//     background: red;
//   }
// </code>
//
// Will compile to:
// <pre>
//   @media screen and (max-width:767px) {
//     background: red;
//   }
// </pre>
// -------------------------------------------------------------------------- //
$width_mobile: 767px;
$width_tablet: 768px;
$width_desktop: 992px;
$width_large: 1200px;

$break_mobile: "(max-width:"$width_mobile")" !default;
$break_tablet: "(min-width:"$width_tablet")" !default;
$break_desktop: "(min-width:"$width_desktop")" !default;
$break_large: "(min-width:"$width_large")" !default;


// -------------------------------------------------------------------------- //
// Resolution breakpoint.
//
// Available variable:
// - $break_retina : Double density screens.
// -------------------------------------------------------------------------- //
$break_retina: "only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (   min--moz-device-pixel-ratio: 2)),
  only screen and (     -o-min-device-pixel-ratio: 2/1),
  only screen and (        min-device-pixel-ratio: 2),
  only screen and (                min-resolution: 192dpi),
  only screen and (                min-resolution: 2dppx)";


// -------------------------------------------------------------------------- //
// REM values.
// -------------------------------------------------------------------------- //
$rem_mobile: 8px !default;
$rem_tablet: 9px !default;
$rem_desktop: 10px !default;
$rem_large: $rem_desktop !default;


// -------------------------------------------------------------------------- //
// IE8 support.
// -------------------------------------------------------------------------- //
$ie8_support: false;


// -------------------------------------------------------------------------- //
// Colors.
//
// Usage:
// <code>
//   a {
//     color: $color_blue;
//   }
// </code>
// -------------------------------------------------------------------------- //
$color_white: #ffffff;
$color_white_dark: #c1b9b3;
$color_black: #3c3c3b;
$color_grey: #ebe9e7;
$color_grey_medium: #777;
$color_grey_dark: #575756;
$color_brown_light: #a0938b;
$color_brown: #62605f;
$color_brown_dark: #432a19;
$color_red: #9b1b3f;
$color_green: #386f1e;

$color_link: $color_red;
$color_link_hover: $color_brown;

// This is Bootstrap's default text color.
$text_color: $color_black;


// -------------------------------------------------------------------------- //
// Fonts stacks.
//
// Usage:
// <code>
//   h1 {
//     font-family: $font_stack;
//   }
// </code>
// -------------------------------------------------------------------------- //

$font_stack: "Gotham-Light", Verdana, Sans-Serif;
$font_stack_italic: "Gotham-LightItalic", Verdana, Sans-Serif;
$font_stack_bold: "Gotham-Medium", Verdana, Sans-Serif;
$font_stack_bold_italic: "Gotham-MediumItalic", Verdana, Sans-Serif;

$font_stack_book: "Gotham-Book", Verdana, Sans-Serif;
$font_stack_heavy: "Gotham-Bold", Verdana, Sans-Serif;


// -------------------------------------------------------------------------- //
// Font sizes.
//
// Font sizes are defined as a mixing as they have:
// - A fallback in px for older browsers.
// - A different size for larger displays.
//
// Usage:
// <code>
//   h1 {
//     @include font_size_h1;
//   }
// </code>
// -------------------------------------------------------------------------- //

@mixin font_size_default () {
  @include rem(font-size, 16px);
}

@mixin font_size_h1 {
  @include font_size_responsive(26px, 40px);
}

@mixin font_size_h2 {
  @include font_size_responsive(24px, 30px);
}

@mixin font_size_h3 {
  @include font_size_responsive(20px, 20px);
}

@mixin font_size_h4 {
  @include font_size_default;
}

@mixin font_size_h5 {
  @include font_size_default;
}

@mixin font_size_h6 {
  @include rem(font-size, 13px);
}

@mixin font_cta () {
  @include rem(font-size, 14px);
  font-family: $font_stack_book;
}


// Font weights.
$font_weight: normal;
$font_weight_bold: bold;

// Line heights.
$font_line_height: 1.5em;
$font_line_height_heading: 1.2em;


// -------------------------------------------------------------------------- //
// Icons
// -------------------------------------------------------------------------- //


// -------------------------------------------------------------------------- //
// Bootstrap variables.
// -------------------------------------------------------------------------- //


// -------------------------------------------------------------------------- //
// Borders
// -------------------------------------------------------------------------- //
$border-radius-base:        5px !default;
$border-radius-large:       10px !default;
$border-radius-small:       3px !default;
