/**
 * Forms and their elements.
 */

input.form-text,
textarea.form-textarea,
.btn {
  @include border_reset;
}

input.form-text,
textarea.form-textarea {
  &.error {
    border: 1px solid $color_red;
  }
}

.chosen-container {
  box-sizing: border-box;
  height: auto;
  line-height: 1em;
  padding: 0;
  width: 100% !important;

  @include border_reset();

  .chosen-choices {
    @include image_background_responsive("../images/dropdown", "png", 16px, 6px);
    background-position: right center;
    background-repeat: no-repeat;
    padding: 5px 15px 5px 10px;
    @include border_reset();

    li.search-field input[type="text"] {
      font-family: $font_stack;
      height: 24px;
    }

    .chosen-search {
      color: $color_black;

      @include border_reset();
    }
  }
  .chosen-drop {
    color: $color_black;

    .chosen-results {
      max-height: 400px;
    }
  }

  a.chosen-single {
    background-image: none;

    height: 34px;
    @include border_reset();

    span, div {
      padding-top: 5px;
      height: 34px;
    }
  }
}

.error-processed > label.control-label {
  color: $color_red;
}
